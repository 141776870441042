import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { createMenu } from '../../../services/menu.service';
import { createUser } from '../../../services/user.service';

const useCreateUser = () => {
  const queryClient = useQueryClient();

  const { mutate: createUserMutate, isLoading } = useMutation({
    mutationFn: createUser,
    mutationKey: ['createUser'],
    onSuccess: () => {
      queryClient.invalidateQueries(['getUsers']);
      toast.success('Create account success');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    createUserMutate({
      ...values,
    });
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useCreateUser;
