import apiClient from './service';

export const deleteUser = (id: string) => {
  return apiClient(true).delete('/users/' + id);
};

export const updateUser = ({ id, data }: any) => {
  return apiClient(true)
    .patch('/users/' + id, data)
    .then((res) => res.data);
};

export const getUsersService = () => {
  return apiClient(true).get('/users', {
    params: {
      limit: 100,
      page: 1,
    },
  });
};
export const createUser = (data: any) => {
  return apiClient(true).post('/users', data);
};
