import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table, Tag, Form, Input } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deletePost, getPosts } from '../../services/post.service';
import LoadingCenter from '../LoadingCenter';
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../utils/error';
import CategoryOption from '../CategoryOption';
import { Link } from 'react-router-dom';

const { Column } = Table;

const PostContainer = () => {
  const [titleFilter, setTitleFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [allValues, setAllValues] = useState({});
  const queryClient = useQueryClient();

  const fetchPosts = () => getPosts(allValues, 'vi');

  const { isLoading, data: postsResponse } = useQuery(
    ['getPosts', allValues],
    fetchPosts
  );

  useEffect(() => {
    queryClient.invalidateQueries(['getPosts', allValues]);
  }, [allValues, queryClient]);

  const { mutate: deletePostMutate, isLoading: isDeleting } = useMutation({
    mutationFn: (input: any) => deletePost(input),
    mutationKey: ['deletePostMutate'],
    onSuccess: () => {
      toast.success('Delete post success');
      queryClient.invalidateQueries(['getPosts']);
    },
    onError: (error: any) => {
      const message = getErrorMessage(error);
      toast.error(message);
    },
  });

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    setAllValues({
      ...(allValues?.title && { title: allValues.title }),
      ...(allValues?.slug && { slug: allValues.slug }),
      ...(allValues?.type && { type: allValues.type }),
    });
  };

  return (
    <>
      <div className='p-4'>
        <div className='overview mb-4 text-right'>
          <b>Tổng: {postsResponse?.posts.length || 0} bài viết</b>
        </div>
        <div className='overview mb-4 mt-2 text-right'>
          <Button size='small' type='primary'>
            <Link to='/posts/newpost'>
              {' '}
              <PlusCircleOutlined />
              <span className='ml-2'>Tạo bài viết mới</span>
            </Link>
          </Button>
        </div>
        <div className='mb-4'>
          <Form
            layout='inline'
            className='w-full'
            onValuesChange={handleFormValuesChange}
          >
            <Form.Item name='title'>
              <Input placeholder='Search title ...' allowClear />
            </Form.Item>
            <Form.Item name='slug'>
              <Input placeholder='Search slug ...' allowClear />
            </Form.Item>
            <Form.Item name='type' className='w-56'>
              <CategoryOption />
            </Form.Item>
          </Form>
        </div>
        {isLoading ? (
          <LoadingCenter />
        ) : (
          <Table dataSource={postsResponse?.posts || []} loading={isLoading}>
            <Column title='Title' dataIndex='title' key='title' />
            <Column title='Slug' dataIndex='slug' key='slug' />
            <Column
              title='Type'
              dataIndex='type'
              key='type'
              render={(type) => {
                if (type === 'POST') return 'Tài liệu';
                return 'Trang liên kết';
              }}
            />
            <Column
              title='Created At'
              dataIndex='createdDate'
              key='createdDate'
              render={(text) => dayjs(text).format('HH:mm DD/MM/YYYY')}
            />

            <Column
              title='Action'
              key='actions'
              render={(_, row: any) => (
                <div className='flex gap-2' key={row.id}>
                  <Button size='small' type='primary' ghost>
                    <Link to={`/post/edit/${row._id}`}>
                      <EditOutlined />
                    </Link>
                  </Button>
                  <Popconfirm
                    title='Xác nhận xoá'
                    description='Bài viết này sẽ bị xoá và không thể khôi phục lại.'
                    onConfirm={() => deletePostMutate(row?._id)}
                    okText='Xoá'
                    cancelText='Đóng'
                    disabled={isDeleting}
                  >
                    <Button size='small' danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              )}
            />
          </Table>
        )}
      </div>
    </>
  );
};

export default PostContainer;
