import { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Input } from 'antd';
// import UploadImage from '../../../components/UploadImage';
// import CategoryOption from '../../../components/CategoryOption';
import { debounce } from 'lodash';
import { convertToSlug } from '../../../utils/string';
import CategoryOption from '../../CategoryOption';
import Editor from './Editor';

interface Props {
  handleSubmit: (input: any) => void;
  form: any;
  isLoading: boolean;
  initialValues?: any;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PostForm = ({ handleSubmit, form, isLoading, initialValues }: Props) => {
  const urlRef = useRef<any>(null);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  const handleUpdateSlug = (e: any) => {
    const value = e.target.value;
    urlRef.current.value = convertToSlug(value);
    setTitle(value);
  };
  useEffect(() => {
    if (!urlRef?.current || !initialValues) {
      return;
    }

    urlRef.current.value = initialValues?.slug;

    if (initialValues?.content) setContent(initialValues.content);
    setTitle(initialValues.title);
  }, [urlRef, initialValues]);

  const handleFinish = (values: any) => {
    if (initialValues) {
      handleSubmit({
        id: initialValues._id,
        data: {
          ...values,
          slug: urlRef.current.value,
          content,
        },
      });
    } else {
      handleSubmit({
        ...values,
        slug: urlRef.current.value,
        content,
      });
    }
  };

  return (
    <Form
      onFinish={handleFinish}
      layout='vertical'
      form={form}
      initialValues={initialValues}
    >
      <div className='grid grid-cols-12 gap-6'>
        <div className='col-span-7'>
          <Card>
            <div className='flex flex-col justify-between items-center'>
              <Form.Item
                name='title'
                label='Title'
                rules={[{ required: true }]}
                className='w-full'
              >
                <Input
                  placeholder='Post title'
                  onChange={debounce(handleUpdateSlug, 300)}
                />
              </Form.Item>
              <div className='w-full flex items-center mt-[10px]'>
                <span className='w-fit whitespace-nowrap	'>
                  {process.env.REACT_APP_CLIENT_URL}
                </span>
                <input
                  ref={urlRef}
                  className='text-left w-full border-transparent text-orange-600'
                  placeholder='slug'
                />
              </div>
            </div>
          </Card>
        </div>

        <div className='col-span-5'>
          <Card>
            <div className='flex justify-between items-center'>
              <h5 className='font-bold'>Post information</h5>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Publish
              </Button>
            </div>

            <Form.Item
              name='type'
              label='Category'
              rules={[{ required: true }]}
            >
              <CategoryOption />
            </Form.Item>
          </Card>
        </div>
      </div>

      <div className='col-span-12  w-full mt-6 bg-white p-4'>
        <Form.Item name='content' label='Post Content'>
          <Editor setContent={setContent} content={content} />
        </Form.Item>
      </div>
      <div className='bg-white mt-6 p-4 ckeditor '>
        <h3>Preview:</h3>
        <h1 className='mb-2 text-[20px] font-bold text-[#f4742f] md:mb-4 md:text-[1.5em]'>
          {title}
        </h1>

        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Form>
  );
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default PostForm;
