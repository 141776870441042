import React from 'react';
import SliderManager from '../../components/SliderManager';

const SliderPage = () => {
  return (
    <div className='p-6'>
      <SliderManager />
    </div>
  );
};

export default SliderPage;
