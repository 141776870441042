import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { deleteSlider } from '../../../services/slider.service';

const useDeleteSlider = () => {
  const queryClient = useQueryClient();

  const { mutate: deleteSliderMutate, isLoading } = useMutation({
    mutationFn: deleteSlider,
    mutationKey: ['deleteSlider'],
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getSliders']);
      toast.success('Delete slider success');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (value: any) => {
    deleteSliderMutate(value);
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useDeleteSlider;
