import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { updateMenu } from '../../../services/menu.service';

const useUpdateMenu = () => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: updateMenuMutate } = useMutation({
    mutationFn: updateMenu,
    mutationKey: ['updateMenu'],
    onSuccess: async () => {
      toast.success('Update menu success');
      queryClient.invalidateQueries(['getMenus']);
      setIsLoading(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    updateMenuMutate({
      ...values,
    });
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useUpdateMenu;
