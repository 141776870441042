import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const LoadingCenter = () => {
  return (
    <div className='w-full text-5xl text-blue-700 absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center'>
      {/* <LoadingOutlined /> */}
      <Spin>
        <div className='content' />
      </Spin>
    </div>
  );
};

export default LoadingCenter;
