import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { createMenu } from '../../../services/menu.service';

const useCreateMenu = () => {
  const queryClient = useQueryClient();

  const { mutate: createMenuMutate, isLoading } = useMutation({
    mutationFn: createMenu,
    mutationKey: ['createMenu'],
    onSuccess: () => {
      queryClient.invalidateQueries(['getMenus']);
      toast.success('Create menu success');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    createMenuMutate({
      ...values,
    });
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useCreateMenu;
