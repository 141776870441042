import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { updateSlider } from '../../../services/slider.service';

const useUpdateSlider = () => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: updateSliderMutate } = useMutation({
    mutationFn: updateSlider,
    mutationKey: ['updateSlider'],
    onSuccess: async () => {
      queryClient.invalidateQueries(['getSliders']);
      toast.success('Update slider success');
      setIsLoading(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    updateSliderMutate({
      ...values,
    });
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useUpdateSlider;
