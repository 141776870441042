import { create } from 'zustand';
import { User, UserTokens } from '../@types/user';
import { saveUserTokens } from '../utils/auth';

export interface UserStore {
  user?: User;
  login: (user: User, tokens: UserTokens) => any;
  setUser: (user: User) => any;
}

const useUserStore = create((set) => ({
  user: undefined,
  login: (user: User, tokens: UserTokens) => {
    saveUserTokens(tokens);
    return set({
      user,
    });
  },
  setUser: (user: User) => {
    return set({
      user,
    });
  },
}));

export default useUserStore;
