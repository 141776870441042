import React from 'react';
import useUserStore, { UserStore } from '../../stores/useUserStore';
import { useNavigate } from 'react-router-dom';
import { LoginInput, loginService } from '../../services/auth.service';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Form, Input } from 'antd';

const LoginPage = () => {
  const { login } = useUserStore((state) => state as UserStore);

  const navigation = useNavigate();
  const { isLoading, mutate: loginMutate } = useMutation({
    mutationFn: (input: LoginInput) => loginService(input),
    mutationKey: ['login'],
    onSuccess: (response: any) => {
      const { data } = response;
      const { user, tokens } = data;

      if (user.role !== 'admin') {
        toast.error('Sai tên đăng nhập hoặc mật khẩu');
        return;
      }

      if (user && tokens) {
        login(user, tokens);
        toast.success('Đăng nhập thành công');
        setTimeout(() => {
          navigation('/sliders');
        }, 500);
      } else {
        toast.error('Sai tên đăng nhập hoặc mật khẩu');
      }
    },
    onError: () => {
      toast.error('Sai tên đăng nhập hoặc mật khẩu');
    },
  });

  const onFinish = (values: any) => {
    const { password_user, email_user } = values;
    loginMutate({ email: email_user, password: password_user });
  };

  return (
    <div className='flex items-center justify-center h-screen bg-[#0f5899] p-4'>
      <div className='w-full max-w-md p-6 bg-white rounded-lg shadow-md'>
        <div className='text-center mb-3'>
          <a href=''>
            <img src='/img/logo.png' alt='' className='md:w-44 w-24' />
          </a>
        </div>

        <Form
          onFinish={onFinish}
          initialValues={{
            email_user: '',
            password_user: '',
          }}
        >
          <div className='mb-6'>
            <label htmlFor='email' className='block mb-1 font-bold'>
              Email
            </label>
            <Form.Item
              name='email_user'
              rules={[{ required: true, message: 'Vui lòng điền email!' }]}
            >
              <Input
                type='email'
                className='w-full px-3 py-2 border rounded text-[#f4742f] font-[500]'
              />
            </Form.Item>
          </div>

          <div className='mb-6'>
            <label htmlFor='password' className='block mb-1 font-bold'>
              Password
            </label>
            <Form.Item
              name='password_user'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền mật khẩu!',
                },
              ]}
            >
              <Input
                type='password'
                placeholder='*******'
                className='w-full px-3 py-2 border rounded text-[#f4742f]'
              />
            </Form.Item>
          </div>

          <button
            type='submit'
            className='w-full py-2 my-5 bg-blue-500 text-white rounded focus:outline-none hover:bg-blue-600 hover:cursor-pointer text-sm'
            disabled={isLoading}
          >
            Login
          </button>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
