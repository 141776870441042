import { UserTokens } from '../@types/user';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../constant';

export const saveUserTokens = (token: UserTokens) => {
  const { access, refresh } = token;
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(access));
  localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(refresh));
};

export const getAccessToken = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (token) {
    return JSON.parse(token)?.token;
  }
  return undefined;
};

export const clearAuthTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};
