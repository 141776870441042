import React from 'react';
import UserManager from '../../components/UserMananger';

const AccountPage = () => {
  return (
    <div className='mx-auto p-8'>
      <UserManager />
    </div>
  );
};

export default AccountPage;
