import React, { useState, useEffect } from 'react';
import LoadingCenter from '../LoadingCenter';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  UpOutlined,
  DownOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import useGetSliders from './hook/useGetSliders';
import ModalConfirm from '../ModalConfirm';
import useDeleteSlider from './hook/useDeleteSlider';
import CreateSliderModal from './CreateSlider';
import UpdateSliderModal from './UpdateSlider';
import useUpdateSlider from './hook/useUpdateSlider';
import YouTube from 'react-youtube';
import { extractVideoIdFromYouTubeURL } from '../../utils/url';

const SliderManager = () => {
  const { data: SliderItems, isLoading: isLoadingSliders } = useGetSliders();
  const url = process.env.REACT_APP_API_URL;
  const [isOpen, setIsOpen] = useState<any>(false);
  const [idSlider, setIdSlider] = useState();
  const [prop, setProp] = useState<any>({});
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false);
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>();
  const [sliders, setSliders] = useState<any>();

  const { handleSubmit: handleDelete, isLoading: isLoadingDelete } =
    useDeleteSlider();
  const { handleSubmit: handleUpdate, isLoading: isLoadingUpdate } =
    useUpdateSlider();

  useEffect(() => {
    setSliders(SliderItems);
  }, [SliderItems]);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleDeleteSlider = () => {
    handleDelete(idSlider);
    setIsOpen(false);
  };
  const onCloseCreateModal = () => {
    setVisibleCreateModal(false);
  };
  const onCloseUpdateModal = () => {
    setVisibleUpdateModal(false);
  };

  const handleDragDrop = (results: any) => {
    const { source, destination, type, draggableId } = results;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const slider = sliders.find((item: any) => item._id === draggableId);

    if (!slider) {
      return;
    }

    const updatedSliders = sliders.filter(
      (item: any) => item._id !== draggableId
    );

    // Chèn phần tử vào vị trí mới
    updatedSliders.splice(destination.index, 0, slider);
    setSliders(updatedSliders);

    handleUpdate({
      id: draggableId,
      data: {
        position: results?.destination?.index,
      },
    });
  };

  const renderSliderItem = (item: any, index: number) => {
    const videoId = extractVideoIdFromYouTubeURL(item.link) || '';
    return (
      <Draggable draggableId={item._id} key={item._id} index={index}>
        {(provided) => (
          <div
            className='p-4 flex shadow-lg mb-6  bg-white'
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {item.type === 'Link' ? (
              <YouTube
                videoId={videoId}
                className='w-1/3 bg-black justify-center flex'
                opts={{
                  height: '150',
                  width: 'full',
                  playerVars: {
                    rel: 0,
                  },
                }}
              />
            ) : (
              <div className='bg-black w-1/3 h-[150px] flex items-center justify-center'>
                {item.type === 'Video' ? (
                  <video
                    controls
                    className={'w-full h-full'}
                    // onPlay={handleVideoPlay}
                    // onPause={handleVideoPause}
                  >
                    <source src={`${url}${item.url}`} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <div className={'  '}>
                    <img
                      src={`${url}${item.url}`}
                      alt={`Image ${index}`}
                      className={'h-[150px] object-cover w-full'}
                    />
                  </div>
                )}
              </div>
            )}

            <div className=' flex justify-between mx-6 w-full ml-10'>
              <div className='flex flex-col '>
                <span>
                  <b>Tiêu đề: </b>
                  {item?.title}
                </span>
                {item?.type === 'Link' && (
                  <span className='mt-10'>
                    <b>Đường dẫn: </b>

                    {item?.link}
                  </span>
                )}
              </div>
              <div className='flex flex-col justify-around ml-10'>
                <Button
                  type='primary'
                  ghost
                  onClick={() => {
                    setVisibleUpdateModal(true);
                    setItemSelected(item);
                  }}
                >
                  <EditOutlined />
                </Button>

                <Button
                  type='primary'
                  danger
                  ghost
                  onClick={async () => {
                    setIdSlider(item._id);

                    setIsOpen(true);
                    setProp({
                      title: 'Xác nhận',
                      content: 'Bạn chắc chắn muốn xóa bản ghi này ?',
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <>
      {isLoadingSliders ? (
        <LoadingCenter />
      ) : (
        <DragDropContext onDragEnd={handleDragDrop}>
          <Droppable droppableId='droppableId' type='group1'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className=' mb-8    bg-[#a7a6a6] my-2'>
                  <button
                    className='w-full p-2 text-xl font-bold hover:cursor-pointer  border-none shadow-md bg-[#b8b6b6]'
                    onClick={() => setVisibleCreateModal(true)}
                  >
                    <PlusOutlined />
                  </button>
                </div>
                {sliders?.map((item: any, index: any) =>
                  renderSliderItem(item, index)
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <ModalConfirm
        isOpen={isOpen}
        onOk={handleDeleteSlider}
        onCancel={handleClose}
        prop={prop}
      />

      <CreateSliderModal
        visible={visibleCreateModal}
        onCancel={onCloseCreateModal}
      />

      <UpdateSliderModal
        visible={visibleUpdateModal}
        onCancel={onCloseUpdateModal}
        itemSelected={itemSelected}
      />
    </>
  );
};

export default SliderManager;
