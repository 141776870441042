import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { updatePost } from '../../../services/post.service';
import { useNavigate } from 'react-router-dom';

const useUpdatePost = () => {
  const navigate = useNavigate();
  const { mutate: updatePostMutate, isLoading } = useMutation({
    mutationFn: updatePost,
    mutationKey: ['updatePost'],
    onSuccess: () => {
      toast.success('Update post success');
      navigate('/posts');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    updatePostMutate({
      ...values,
    });
  };
  return {
    handleSubmit,
    isLoading,
  };
};

export default useUpdatePost;
