import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { createMenu } from '../../../services/menu.service';
import { updateUser } from '../../../services/user.service';

const useUpdateUser = () => {
  const queryClient = useQueryClient();

  const { mutate: updateUserMutate, isLoading } = useMutation({
    mutationFn: updateUser,
    mutationKey: ['updateUser'],
    onSuccess: () => {
      queryClient.invalidateQueries(['getUsers']);
      toast.success('Update account success');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    updateUserMutate({
      ...values,
    });
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useUpdateUser;
