import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';

type MenuFormProps = {
  requiredPassword: boolean;
};

const UserForm: React.FC<MenuFormProps> = ({ requiredPassword }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      <Form.Item name='name' label='Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        name='email'
        label='Email'
        rules={[
          { required: true },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name='password'
        label='Password'
        rules={[
          { required: requiredPassword },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (requiredPassword && value.length < 8) {
                return Promise.reject(
                  new Error('Password must be at least 8 characters')
                );
              }

              if (!requiredPassword && value && value.length < 8) {
                return Promise.reject(
                  new Error('Password must be at least 8 characters')
                );
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
    </>
  );
};

export default UserForm;
