import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { createSlider } from '../../../services/slider.service';

const useCreateSlider = () => {
  const queryClient = useQueryClient();

  const { mutate: createSliderMutate, isLoading } = useMutation({
    mutationFn: createSlider,
    mutationKey: ['createSlider'],
    onSuccess: () => {
      queryClient.invalidateQueries(['getSliders']);
      toast.success('Create slider success');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    createSliderMutate({
      ...values,
    });
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useCreateSlider;
