import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import axios from 'axios';
import { uploadService } from '../../services/upload.service';

const ImageUploader = ({ type, mediaUrl, setMediaUrl, setType }: any) => {
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file: any) => {
    const checkVideo = file.type.startsWith('video/');
    const checkImage = file.type.startsWith('image/');
    if (checkVideo || checkImage) {
      setType(checkVideo ? 'Video' : 'Image');
      return true;
    }
    message.error('Tệp tin không hợp lệ hoặc không thể xác định !');

    return false;
  };

  const handleChange = async (info: any) => {
    setMediaUrl(null);
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      try {
        setMediaUrl(info?.file?.response?.url);
        setLoading(false);
      } catch (error) {
        console.error('Upload error:', error);
        setLoading(false);
        message.error('Failed to upload image');
      }
    }
  };
  const sendRequest = async ({ onSuccess, onError, file, onProgress }: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const data = await uploadService(formData);
    onSuccess({ success: true, url: data.path });
  };

  const uploadButton = (
    <div
      className='absolute top-0 bottom-0 right-0 left-0 bg-white  flex flex-col justify-center 
    items-center  border-dashed border-2 border-[#ccc] hover:border-blue-500'
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className='text-center'>
      <Upload
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={sendRequest}
      >
        <div className='absolute bottom-0 top-0 right-0 left-0 bg-black '>
          {mediaUrl ? (
            type === 'Image' ? (
              <img
                src={process.env.REACT_APP_API_URL + mediaUrl}
                alt='avatar'
                className='w-full h-full object-cover'
              />
            ) : (
              <video controls className='w-full h-full '>
                <source
                  src={process.env.REACT_APP_API_URL + mediaUrl}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            )
          ) : (
            uploadButton
          )}
        </div>
      </Upload>
    </div>
  );
};

export default ImageUploader;
