import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  MenuFoldOutlined,
  AreaChartOutlined,
  UserOutlined,
  TeamOutlined,
  MailOutlined,
  FormOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import { clearAuthTokens } from '../../utils/auth';

const NavbarContainer = ({ isOpenNavbar, toggleNavbar }: any) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div
      className={`w-60 flex flex-col bg-gray-900 text-white  ${
        isOpenNavbar
          ? 'block absolute z-50 md:relative  bottom-0 top-0'
          : 'hidden md:block md:w-[88px]'
      }`}
    >
      <div className='flex items-center justify-between p-4'>
        <img src='/img/logo.png' alt='ảnh logo' className='w-14 h-auto p-0' />
        <button
          className='text-2xl md:hidden  appearance-none border-none bg-transparent p-0 m-0 cursor-pointer hover:text-orange-600'
          onClick={toggleNavbar}
        >
          <MenuFoldOutlined />
        </button>
      </div>
      <hr className='w-1/2 ml-0' />
      <ul className='list-none flex flex-col px-4 justify-center'>
        {/* <li className='md:my-2 p-2  w-full'>
          <Link
            to='/'
            className={`hover:cursor-pointer  link-nav no-underline text-[#ccc] hover:text-orange-600 flex items-center ${
              currentPath === '/' ? 'link-nav-active text-orange-600' : ''
            }`}
          >
            <AreaChartOutlined className='md:text-2xl text-xl ' />
            <span
              className={`text-sm ml-2 font-[500] ${
                isOpenNavbar ? 'inline' : 'hidden md:none'
              }`}
            >
              Dashboard
            </span>
          </Link>
        </li> */}
        <li className='md:my-2 p-2 '>
          <Link
            to='/sliders'
            className={`hover:cursor-pointer link-nav no-underline text-[#ccc] hover:text-orange-600 flex items-center ${
              currentPath === '/sliders'
                ? 'link-nav-active text-orange-600'
                : ''
            }`}
          >
            <ControlOutlined className='md:text-2xl text-xl  ' />
            <span
              className={`text-sm ml-2 font-[500] ${
                isOpenNavbar ? 'inline' : 'hidden md:none'
              }`}
            >
              Sliders
            </span>
          </Link>
        </li>
        <li className='md:my-2 p-2 '>
          <Link
            to='/menus'
            className={`hover:cursor-pointer link-nav no-underline text-[#ccc] hover:text-orange-600 flex items-center ${
              currentPath === '/menus' ? 'link-nav-active text-orange-600' : ''
            }`}
          >
            <AppstoreOutlined className='md:text-2xl text-xl  ' />
            <span
              className={`text-sm ml-2 font-[500] ${
                isOpenNavbar ? 'inline' : 'hidden md:none'
              }`}
            >
              Menus
            </span>
          </Link>
        </li>
        <li className='md:my-2 p-2 '>
          <Link
            to='/posts'
            className={`hover:cursor-pointer link-nav no-underline text-[#ccc] hover:text-orange-600 flex items-center ${
              currentPath === '/posts' ? 'link-nav-active text-orange-600' : ''
            }`}
          >
            <FormOutlined className='md:text-2xl text-xl  ' />
            <span
              className={`text-sm ml-2 font-[500] ${
                isOpenNavbar ? 'inline' : 'hidden md:none'
              }`}
            >
              Posts
            </span>
          </Link>
        </li>

        <li className='md:my-2 p-2 '>
          <Link
            to='/accounts'
            className={`hover:cursor-pointer link-nav no-underline text-[#ccc] hover:text-orange-600 flex items-center ${
              currentPath === '/accounts'
                ? 'link-nav-active text-orange-600'
                : ''
            }`}
          >
            <TeamOutlined className='md:text-2xl text-xl' />
            <span
              className={`text-sm ml-2 font-[500] ${
                isOpenNavbar ? 'inline' : 'hidden md:none'
              }`}
            >
              Accounts
            </span>
          </Link>
        </li>
        <li className='md:my-2 p-2 '>
          <Link
            to='/login'
            className='hover:cursor-pointer link-nav no-underline text-[#ccc] hover:text-orange-600 flex items-center'
            onClick={clearAuthTokens}
          >
            <LogoutOutlined className='md:text-2xl text-xl  ' />
            <span
              className={`text-sm ml-2 font-[500] ${
                isOpenNavbar ? 'inline' : 'hidden md:none'
              }`}
            >
              Logout
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavbarContainer;
