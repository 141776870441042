import UserTable from './userTable';

const UserManager = () => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='card form-group'>
          <div className='card-header bg-primary  area-note'></div>
          <div className='card-body'>
            <UserTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManager;
