import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { createPost } from '../../../services/post.service';
import { useNavigate } from 'react-router-dom';

const useCreatePost = () => {
  const navigate = useNavigate();
  const { mutate: createPostMutate, isLoading } = useMutation({
    mutationFn: createPost,
    mutationKey: ['createPost'],
    onSuccess: () => {
      toast.success('Create post success');
      navigate('/posts');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (values: any) => {
    createPostMutate({
      ...values,
    });
  };
  return {
    handleSubmit,
    isLoading,
  };
};

export default useCreatePost;
