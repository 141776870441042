import apiClient from './service';

export const getSliders = () => {
  return apiClient(false)
    .get('/sliders')
    .then((res) => res.data);
};
export const createSlider = (data: any) => {
  return apiClient(true)
    .post('/sliders', data)
    .then((res) => res.data);
};

export const updateSlider = ({ id, data }: any) => {
  return apiClient(true)
    .patch('/sliders/' + id, data)
    .then((res) => res.data);
};

export const getSlider = (id: string) => {
  return apiClient()
    .get('/sliders/' + id)
    .then((res) => res.data);
};
export const deleteSlider = (id: string) => {
  return apiClient()
    .delete('/sliders/' + id)
    .then((res) => res.data);
};
