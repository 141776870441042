import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import useUpdateSlider from '../hook/useUpdateSlider';
import SliderForm from '../SliderForm';
import ImageUploader from '../../Upload';

type SliderFormProps = {
  visible: boolean;
  onCancel: () => void;
  itemSelected: any;
};

const UpdateSliderModal: React.FC<SliderFormProps> = ({
  visible,
  onCancel,
  itemSelected,
}) => {
  const [form] = Form.useForm();
  const { handleSubmit, isLoading } = useUpdateSlider();

  const [mediaUrl, setMediaUrl] = useState<string | undefined>();
  const [type, setType] = useState<string>('Image');

  useEffect(() => {
    if (type !== 'Link') {
      form.setFieldsValue({ url: mediaUrl, type, link: '' });
    } else {
      setMediaUrl('');
    }
  }, [mediaUrl, type, form]);

  useEffect(() => {
    form.setFieldsValue(itemSelected);
    if (itemSelected?.url) setMediaUrl(itemSelected.url);
    if (itemSelected?.type) setType(itemSelected.type);
  }, [itemSelected, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit({
        id: itemSelected._id,
        data: {
          ...values,
          position: 0,
        },
      });
      form.resetFields();
      onCancel();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title={'Update slider'}
      onCancel={onCancel}
      footer={[
        <Button
          key='cancel'
          onClick={() => {
            onCancel();
            form.resetFields();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='Update'
          type='primary'
          onClick={handleOk}
          loading={isLoading}
        >
          Update
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical' className='mt-4'>
        {type !== 'Link' && (
          <Form.Item
            name='url'
            label='Image / video'
            rules={[{ required: true }]}
          >
            <ImageUploader
              mediaUrl={mediaUrl}
              type={type}
              setMediaUrl={setMediaUrl}
              setType={setType}
            />
            <Input hidden />
          </Form.Item>
        )}

        <SliderForm type={type} setType={setType} />
      </Form>
    </Modal>
  );
};

export default UpdateSliderModal;
