import React, { useState } from 'react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useMutation } from '@tanstack/react-query';
import { uploadService } from '../../../services/upload.service';
import { toast } from 'react-toastify';

function uploadAdapter(loader: any) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file: any) => {
          body.append('file', file);
          uploadService(body)
            .then((res) => {
              console.log(res);
              // resolve({
              //   default: `${process.env.REACT_APP_API_URL}/storage/2023/09/13/1694563461206_membershipcertificatehungarianpecs.pdf`,
              // });

              resolve({
                default: `${process.env.REACT_APP_API_URL}${res?.path}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}

function uploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}
function Editor({ setContent, content }: any) {
  const handleChange = (event: any, editor: any) => {
    const data = editor.getData();
    setContent(data);
  };

  return (
    <div>
      <div className='ckeditor'>
        {' '}
        <h3>Post Content </h3>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={handleChange}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          disableWatchdog={true}
        />
      </div>
    </div>
  );
}

export default Editor;
