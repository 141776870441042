import React, { useState, useEffect } from 'react';

import { Form, Input, Select, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import axios from 'axios';
import ImageUploader from '../../Upload';

const SliderForm: React.FC<any> = ({ type, setType }) => {
  const handleTypeChange = (value: string) => {
    setType(value);
  };

  return (
    <>
      <Form.Item name='type' label='Type'>
        <Select defaultValue={type} onChange={handleTypeChange}>
          <Select.Option value='Image' disabled={true && type !== 'Link'}>
            Image
          </Select.Option>
          <Select.Option value='Video' disabled={true && type !== 'Link'}>
            Video
          </Select.Option>
          <Select.Option value='Link'>Link Youtobe</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name='title' label='Title' rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      {type === 'Link' && (
        <Form.Item name='link' label='Link' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      )}
    </>
  );
};

export default SliderForm;
