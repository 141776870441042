import { Select } from 'antd';

const CategoryOption = (props: any) => {
  const options = [
    {
      label: 'Tài liệu',
      value: 'POST',
    },
    {
      label: 'Trang liên kết',
      value: 'MENU',
    },
  ];

  return (
    <Select
      placeholder='Select type'
      options={options}
      showSearch
      allowClear
      {...props}
    />
  );
};

export default CategoryOption;
