import React from 'react';
import PostContainer from '../../components/PostManagerPage';

const Postpage = () => {
  return (
    <div>
      <PostContainer />
    </div>
  );
};

export default Postpage;
