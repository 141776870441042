import { Form, Input } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { convertToSlug } from '../../../utils/string';

type MenuFormProps = {
  requiredUrl: boolean;
  form: any;
};

const MenuForm: React.FC<MenuFormProps> = ({ requiredUrl, form }) => {
  const urlRef = useRef<any>(null);
  const handleUpdateSlug = (e: any) => {
    if (requiredUrl) {
      const value = e.target.value;
      urlRef.current.value = convertToSlug(value);

      form.setFieldsValue({
        url: convertToSlug(value),
      });
    }
  };

  return (
    <>
      <Form.Item name='name' label='Name' rules={[{ required: true }]}>
        <input
          onChange={debounce(handleUpdateSlug, 100)}
          className='w-full p-2 rounded-md'
        />
      </Form.Item>

      <Form.Item name='url' label='URL' rules={[{ required: requiredUrl }]}>
        <input ref={urlRef} className='w-full p-2 rounded-md' />
      </Form.Item>
    </>
  );
};

export default MenuForm;
