import apiClient from './service';

export const createMenu = (data: any) => {
  return apiClient().post('/menus', data);
};

export const getMenus = (type: string) => {
  return apiClient()
    .get('/Menus', {
      params: {
        type,
      },
    })
    .then((res) => res.data);
};

export const getMenu = (id: string) => {
  return apiClient()
    .get('/Menus/' + id)
    .then((res) => res.data);
};

export const deleteMenu = (id: string) => {
  return apiClient()
    .delete('/Menus/' + id)
    .then((res) => res.data);
};

export const updateMenu = ({ id, data }: any) => {
  return apiClient()
    .patch('/Menus/' + id, data)
    .then((res) => res.data);
};
