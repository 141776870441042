import React, { useEffect } from 'react';
import { Modal, Tabs, Form, Button } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from 'react-toastify';
import { getErrorMessage } from '../../utils/error';
import UserForm from './userForm';
import useCreateUser from './hook/createUser';

const { TabPane } = Tabs;

interface ModalWithFormsProps {
  openCreateUser: any;
  onCancel: () => void;
}

const CreateModal: React.FC<ModalWithFormsProps> = ({
  openCreateUser,
  onCancel,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, []);
  const { handleSubmit, isLoading } = useCreateUser();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit({ ...values, role: 'admin' });
      form.resetFields();
      onCancel();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Modal
      title='Tạo mới tài khoản'
      visible={openCreateUser}
      onCancel={onCancel}
      footer={[
        <Button
          key='cancel'
          onClick={() => {
            onCancel();
            form.resetFields();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='create'
          type='primary'
          onClick={handleOk}
          loading={isLoading}
        >
          Create
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical'>
        <UserForm requiredPassword={true} />
      </Form>
    </Modal>
  );
};

export default CreateModal;
