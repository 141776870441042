import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { deleteMenu } from '../../../services/menu.service';

const useDeleteMenu = () => {
  const queryClient = useQueryClient();

  const { mutate: deleteMenuMutate, isLoading } = useMutation({
    mutationFn: deleteMenu,
    mutationKey: ['deleteMenu'],
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getMenus']);
      toast.success('Delete menu success');
    },
    onError: (error: any) => {
      toast.error(error?.response?.message);
    },
  });

  const handleSubmit = (value: any) => {
    deleteMenuMutate(value);
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useDeleteMenu;
