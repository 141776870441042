import React, { useEffect } from 'react';
import { Modal, Tabs, Form, Button } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from 'react-toastify';
import { getErrorMessage } from '../../utils/error';
import UserForm from './userForm';
import useUpdateUser from './hook/useUpdateUser';

const { TabPane } = Tabs;

interface ModalWithFormsProps {
  selectedUser: any;
  onCancel: () => void;
}

const EditModal: React.FC<ModalWithFormsProps> = ({
  selectedUser,
  onCancel,
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(selectedUser);
  }, [selectedUser]);
  const { handleSubmit, isLoading } = useUpdateUser();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit({
        id: selectedUser.id,
        data: {
          name: values?.name,
          email: values?.email,
          ...(values?.password && {
            password: values?.password,
          }),
        },
      });
      form.resetFields();
      onCancel();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const visible = !!selectedUser;

  return (
    <Modal
      title='Chỉnh sửa tài khoản'
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key='update'
          type='primary'
          onClick={handleOk}
          loading={isLoading}
        >
          Update
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical'>
        <UserForm requiredPassword={false} />
      </Form>
    </Modal>
  );
};

export default EditModal;
