import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import useCreateSlider from '../hook/useCreateSlider';
import SliderForm from '../SliderForm';
import ImageUploader from '../../Upload';

type SliderFormProps = {
  visible: boolean;
  onCancel: () => void;
};

const CreateSliderModal: React.FC<SliderFormProps> = ({
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { handleSubmit, isLoading } = useCreateSlider();
  const [loading, setLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState<string>();
  const [type, setType] = useState<string>('Image');

  useEffect(() => {
    if (type !== 'Link') {
      form.setFieldsValue({ url: mediaUrl, type });
    } else {
      setMediaUrl('');
    }
  }, [mediaUrl, type]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      handleSubmit({
        ...values,
        position: 0,
      });
      form.resetFields();
      setType('Image');
      setMediaUrl('');
      onCancel();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        title={'Create slider'}
        onCancel={onCancel}
        footer={[
          <Button
            key='cancel'
            onClick={() => {
              onCancel();
              form.resetFields();
              setMediaUrl('');
              setType('Image');
            }}
          >
            Cancel
          </Button>,
          <Button
            key='create'
            type='primary'
            onClick={handleOk}
            loading={isLoading}
          >
            Create
          </Button>,
        ]}
      >
        <Form form={form} layout='vertical' className='mt-4'>
          {type !== 'Link' && (
            <Form.Item
              name='url'
              label='Image / video'
              rules={[{ required: true }]}
            >
              <ImageUploader
                mediaUrl={mediaUrl}
                type={type}
                setMediaUrl={setMediaUrl}
                setType={setType}
              />
              <Input hidden />
            </Form.Item>
          )}

          <SliderForm type={type} setType={setType} />
        </Form>
      </Modal>
    </>
  );
};

export default CreateSliderModal;
