import apiClient from './service';

export const createPost = (data: any) => {
  return apiClient().post('/posts', data);
};

export const getPosts = (query: any, lang: string) => {
  return apiClient()
    .get('posts/', {
      params: {
        query,
      },
    })
    .then((res) => res.data);
};

export const getPost = (id: string) => {
  return apiClient()
    .get('/posts/' + id)
    .then((res) => res.data);
};

export const deletePost = (id: string) => {
  return apiClient()
    .delete('/posts/' + id)
    .then((res) => res.data);
};

export const updatePost = ({ id, data }: any) => {
  return apiClient()
    .put('/posts/' + id, data)
    .then((res) => res.data);
};
