import React from 'react';
import { Button, Form, Modal } from 'antd';
import MenuForm from '../MenuForm';
import useCreateMenu from '../hook/useCreateMenu';

type MenuFormProps = {
  visible: boolean;
  onCancel: () => void;
  parent?: any;
  position: any;
  type: string;
};

const CreateMenuModal: React.FC<MenuFormProps> = ({
  visible,
  onCancel,
  parent,
  type,
  position,
}) => {
  const [form] = Form.useForm();
  const { handleSubmit, isLoading } = useCreateMenu();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit({
        ...values,
        ...(parent && { parentId: parent }),
        position,
        type,
      });
      form.resetFields();
      onCancel();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const titlte = parent ? 'Create sub menu' : 'Create new root menu';

  return (
    <Modal
      visible={visible}
      title={titlte}
      onCancel={onCancel}
      footer={[
        <Button
          key='cancel'
          onClick={() => {
            onCancel();
            form.resetFields();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='create'
          type='primary'
          onClick={handleOk}
          loading={isLoading}
        >
          Create
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical' className='mt-4'>
        <MenuForm requiredUrl={type === 'HEADER'} form={form} />
      </Form>
    </Modal>
  );
};

export default CreateMenuModal;
