import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import './assets/style.css';
import CreatePostPage from './components/PostManagerPage/CreatePostPage';
import DynamicMenuPage from './pages/DynamicMenu';
import AccountPage from './pages/AccountPage';
import Postpage from './pages/PostPage';
import UpdatePostPage from './components/PostManagerPage/UpdatePostPage';
import SliderPage from './pages/SliderPage';
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      // {
      //   path: '/',
      //   element: <DashboardPage />,
      // },
      {
        path: '/accounts',
        element: <AccountPage />,
      },
      {
        path: '/post/edit/:postId',
        element: <UpdatePostPage />,
      },
      {
        path: '/posts/newpost',
        element: <CreatePostPage />,
      },
      {
        path: '/posts',
        element: <Postpage />,
      },
      {
        path: '/menus',
        element: <DynamicMenuPage />,
      },
      {
        path: '/sliders',
        element: <SliderPage />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]);

const antdConfigs = {
  token: {
    colorPrimary: '#222fb9',
    fontSize: 16,
    borderRadius: 4,
    wireframe: false,
    controlHeight: 40,
  },
};

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={antdConfigs}>
        <RouterProvider router={router} />
      </ConfigProvider>
      <ToastContainer />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
