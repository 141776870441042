import axios from 'axios';
import { getAccessToken } from '../utils/auth';
import configs from '../constant/config';
import { LANG_KEY } from '../components/SwitchLanguages';

const apiClient = (isAuth?: boolean) => {
  // JWT authentication
  let headers: any = {
    Lang: localStorage.getItem(LANG_KEY),
  };

  if (isAuth) {
    const accessToken = getAccessToken();
    if (accessToken && accessToken !== '') {
      headers = {
        ...headers,
        Authorization: 'Bearer ' + accessToken,
      };
    }
  }

  return axios.create({
    baseURL: configs.apiURL,
    headers,
  });
};

export default apiClient;
