import React from 'react';
import { Radio } from 'antd';

export const LANG_KEY = 'lang';
const SelectedLanguage = () => {
  const selectedLang = localStorage.getItem(LANG_KEY);

  const handleSelect = (e: any) => {
    const value = e.target.value;
    localStorage.setItem(LANG_KEY, value);
    window.location.reload();
    return;
  };

  return (
    <div className='w-full flex items-center justify-center scale-75'>
      <Radio.Group value={selectedLang} onChange={handleSelect}>
        <Radio.Button value='en'>English</Radio.Button>
        <Radio.Button value='vi'>Vietnames</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default SelectedLanguage;
