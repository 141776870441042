import React from 'react';
import DynamicMenu from '../../components/DynamicMenu';

const DynamicMenuPage = () => {
  return (
    <div className='mx-auto w-2/3'>
      <DynamicMenu />
    </div>
  );
};

export default DynamicMenuPage;
