import configs from '../constant/config';

export const getStorageUrl = (path: string) => {
  return configs.storageUrl + path;
};

export const extractVideoIdFromYouTubeURL = (url: string) => {
  // Kiểm tra xem URL có chứa "youtube.com" hay không
  if (url?.includes('youtube.com')) {
    // Trích xuất videoId từ URL
    const videoIdMatch = url.match(/[?&]v=([^&]+)/);
    if (videoIdMatch) {
      return videoIdMatch[1];
    }
  }

  // Kiểm tra xem URL có chứa "youtu.be" hay không
  if (url?.includes('youtu.be/')) {
    const videoIdMatch = url.match(/youtu.be\/([^/]+)/);
    if (videoIdMatch) {
      return videoIdMatch[1];
    }
  }

  // Trả về null nếu không tìm thấy videoId
  return null;
};
