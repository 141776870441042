import { Button, Popconfirm, Table, Tag } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import EditModal from './editModal';
import { USER_ROLES } from '../../constant';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../utils/error';
import { deleteUser, getUsersService } from '../../services/user.service';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import CreateModal from './createModal';
const { Column } = Table;

const getRoleTag = (role: number) => {
  const { label, color } = USER_ROLES[Number(role)] || USER_ROLES[3];
  return <Tag color={color}>{label}</Tag>;
};

const UserTable = () => {
  const { isLoading, data: userResponse } = useQuery({
    queryFn: () => getUsersService(),
    queryKey: ['getUsers'],
  });

  const users = userResponse?.data?.results || [];

  const queryClient = useQueryClient();
  const { mutate: deleteUserMutate, isLoading: isDeleting } = useMutation({
    mutationFn: (input: any) => deleteUser(input),
    mutationKey: ['deleteUserMutate'],
    onSuccess: () => {
      toast.success('Delete account success');
      queryClient.invalidateQueries(['getUsers']);
    },
    onError: (error: any) => {
      const message = getErrorMessage(error);
      toast.error(message);
    },
  });

  const [selectedUser, setSelectedUser] = useState<any>();
  const [openCreateUser, setOpenCreateUser] = useState<any>(false);

  return (
    <>
      <div className='overview mb-4 text-right'>
        <b>Tổng: {users?.length} tài khoản</b>
      </div>
      <div className='overview mb-4 mt-2 text-right'>
        <Button
          size='small'
          type='primary'
          onClick={() => setOpenCreateUser(true)}
        >
          <PlusCircleOutlined />
          Thêm tài khoản
        </Button>
      </div>
      <Table dataSource={users} loading={isLoading}>
        <Column title='Name' dataIndex='name' key='name' />
        <Column title='Email' dataIndex='email' key='email' />
        {/* <Column
          title='Info'
          dataIndex='info'
          key='info'
          render={(info) => (
            <ul>
              {info &&
                Object.entries(JSON.parse(info)).map(([key, value]: any) => {
                  return (
                    <li key='as'>
                      {key.toString().toUpperCase()}: <strong>{value}</strong>
                    </li>
                  );
                })}
            </ul>
          )}
        /> */}

        <Column
          title='Created At'
          dataIndex='createdDate'
          key='createdDate'
          render={(text) => dayjs(text).format('HH:mm DD/MM/YYYY')}
        />

        <Column
          title='Action'
          key='actions'
          render={(_, row: any) => (
            <div className='flex gap-2' key={row.id}>
              <Button
                size='small'
                type='primary'
                ghost
                onClick={() => {
                  setSelectedUser(row);
                }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title='Xác nhận xoá'
                description='Nguời dùng này sẽ bị xoá và không thể khôi phục lại.'
                onConfirm={() => deleteUserMutate(row?.id || '')}
                okText='Xoá'
                cancelText='Đóng'
                disabled={isDeleting}
              >
                <Button size='small' danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <EditModal
        key={selectedUser?.id}
        selectedUser={selectedUser}
        onCancel={() => setSelectedUser(undefined)}
      />
      <CreateModal
        openCreateUser={openCreateUser}
        onCancel={() => setOpenCreateUser(false)}
      />
    </>
  );
};

export default UserTable;
