import { useQuery } from '@tanstack/react-query';
import { getMenus } from '../../../services/menu.service';

const useGetMenus = (type: string) => {
  const { data, isLoading } = useQuery(
    ['getMenus', type],
    () => getMenus(type),
    {}
  );

  return { data, isLoading };
};

export default useGetMenus;
