import { Form, Button } from 'antd';
import PostForm from '../Common/PostForm';
import useCreatePost from './useCreatePost';
import Editor from '../Common/Editor';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

function CreatePostPage() {
  const { handleSubmit, isLoading } = useCreatePost();
  const [form] = Form.useForm();

  return (
    <div className='mt-4'>
      <div className='mb-6'>
        <Button size='small' type='primary' ghost>
          <Link to='/posts'>
            <ArrowLeftOutlined />
            <span className='ml-2'>Back</span>
          </Link>
        </Button>
      </div>
      <PostForm handleSubmit={handleSubmit} isLoading={isLoading} form={form} />
    </div>
  );
}

export default CreatePostPage;
