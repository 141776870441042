import { Form, Button } from 'antd';
import PostForm from '../Common/PostForm';
import Editor from '../Common/Editor';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import useUpdatePost from './useUpdatePost';
import { useQuery } from '@tanstack/react-query';
import { getPost } from '../../../services/post.service';
import { toast } from 'react-toastify';
import LoadingCenter from '../../LoadingCenter';
import { parse } from 'node-html-parser';

function UpdatePostPage() {
  const { postId } = useParams();

  const { handleSubmit, isLoading: updateLoading } = useUpdatePost();
  const [form] = Form.useForm();

  const { isLoading, data } = useQuery({
    queryFn: () => getPost(postId || ''),
    queryKey: ['getPost', postId],
    onError: () => {
      toast.error('Post not exits');
    },
    enabled: !!postId,
  });

  return (
    <>
      {isLoading || updateLoading ? (
        <LoadingCenter />
      ) : (
        <div className='mt-4'>
          <div className='mb-6'>
            <Button size='small' type='primary' ghost>
              <Link to='/posts'>
                <ArrowLeftOutlined />
                <span className='ml-2'>Back</span>
              </Link>
            </Button>
          </div>
          <PostForm
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            form={form}
            initialValues={{
              ...data,
              ...(data?.content && {
                content: parse(data?.content).textContent,
              }),
            }}
          />
        </div>
      )}
    </>
  );
}

export default UpdatePostPage;
