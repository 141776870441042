import { useQuery } from '@tanstack/react-query';
import { getSliders } from '../../../services/slider.service';

const useGetSliders = () => {
  const { data, isLoading } = useQuery(['getSliders'], getSliders);

  return { data, isLoading };
};

export default useGetSliders;
