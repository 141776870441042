import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import MenuForm from '../MenuForm';
import { toast } from 'react-toastify';
import useUpdateMenu from '../hook/useUpdateMenu';

type MenuFormProps = {
  visible: boolean;
  onCancel: () => void;
  itemSelected?: any;
};

const EditMenuModal: React.FC<MenuFormProps> = ({
  visible,
  onCancel,
  itemSelected,
}) => {
  const [form] = Form.useForm();
  const { handleSubmit, isLoading } = useUpdateMenu();
  const [requiredUrl, setRequiredUrl] = useState(true);

  useEffect(() => {
    if (itemSelected?.children) setRequiredUrl(false);
    else {
      setRequiredUrl(true);
    }
    form.setFieldsValue({
      name: itemSelected?.name || '',
      url: itemSelected?.url || '',
    });
  }, [itemSelected]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit({
        id: itemSelected._id,
        data: {
          name: values?.name,
          ...(!itemSelected?.children && {
            url: values.url,
          }),
        },
      });
      form.resetFields();
      onCancel();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title='Edit menu'
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key='update'
          type='primary'
          onClick={handleOk}
          loading={isLoading}
        >
          Update
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical'>
        <MenuForm
          requiredUrl={itemSelected?.type === 'FOOTER' ? false : requiredUrl}
          form={form}
        />
      </Form>
    </Modal>
  );
};

export default EditMenuModal;
