import apiClient from './service';

export interface LoginInput {
  email: string,
  password: string
}

export const loginService = (input: LoginInput) => {
  const { email, password } = input;
  return apiClient().post('/auth/login', {
    email,
    password,
  });
};

export const getMeService = () => {
  return apiClient(true).get('/auth/me');
};
