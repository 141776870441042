import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getMeService } from './services/auth.service';
import useUserStore, { UserStore } from './stores/useUserStore';
import LoadingScreen from './components/LoadingScreen';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  EyeFilled,
} from '@ant-design/icons';
import NavbarContainer from './components/Navbar';

const App: React.FC = () => {
  const URL_CONSTANT = [
    // {
    //   key: '/',
    //   value: 'Quản lý Thống Kê',
    // },
    {
      key: '/sliders',
      value: 'Quản lý Slider',
    },
    {
      key: '/menus',
      value: 'Quản lý Menu',
    },
    {
      key: '/posts',
      value: 'Quản lý Bài Viết',
    },
    {
      key: '/accounts',
      value: 'Quản lý Tài Khoản',
    },
  ];

  const { search, pathname } = useLocation();
  const location = useLocation();
  const currentPath = location.pathname;
  const itemUrl: any = URL_CONSTANT.find(
    (item) => item?.key === currentPath
  )?.value;

  const url = [pathname, search].filter(Boolean).join('');

  const navigation = useNavigate();
  const { user, setUser } = useUserStore((store) => store) as UserStore;

  const { isLoading } = useQuery({
    queryFn: () => getMeService(),
    onSuccess: (response) => {
      const _user = response?.data;
      if (_user) {
        setUser(_user);
      }
    },
    onError: () => {
      console.log('error');
      navigation('/login');
    },
    retry: 1,
  });

  const [isOpenNavbar, setIsOpenNavbar] = useState<boolean>(false);
  const toggleNavbar = () => {
    setIsOpenNavbar((prev) => !prev);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className='flex h-screen'>
      {/* Sidebar */}
      <NavbarContainer
        isOpenNavbar={isOpenNavbar}
        toggleNavbar={toggleNavbar}
      />
      <div className='flex-1 flex flex-col'>
        {/* Header */}
        <div className='bg-gray-800 text-white flex p-4 justify-between'>
          <div className=' flex justify-between items-center'>
            <button
              onClick={toggleNavbar}
              className={`md:block text-2xl  appearance-none border-none bg-transparent p-0 m-0 cursor-pointer hover:text-orange-600`}
            >
              {isOpenNavbar ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </button>
            <h2 className='m-0 ml-8 text-xl uppercase'>{itemUrl || ''}</h2>
          </div>
          <div>
            <span>{user?.name}</span>
            <img
              src='/img/avatar.webp'
              alt='logo'
              className='w-12 h-12 rounded-full ml-2 bg-blue-500'
            />
          </div>
        </div>

        {/* Main Content */}
        <div className='flex-1 overflow-y-auto p-4 bg-slate-200'>
          {/* <div className='flex items-center'>
            <EyeFilled className='text-xl mr-2 text-orange-600 font-bold' />
            <span className='text-sm md:text-xl  bg-orange-100 '>{url}</span>
          </div> */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default App;
