import React, { useEffect, useState } from 'react';
import { Menu as AntdMenu } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'tailwindcss/tailwind.css';
import { getMenus } from '../../services/menu.service';
import {
  UpOutlined,
  DownOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  MacCommandOutlined,
} from '@ant-design/icons';
import uuid from 'react-uuid';
import useUpdateMenu from './hook/useUpdateMenu';
import useGetMenus from './hook/useGetMenus';
import LoadingCenter from '../LoadingCenter';
import ModalConfirm from '../ModalConfirm';
import useDeleteMenu from './hook/useDeleteMenu';
import CreateMenuModal from './CreateMenu';
import EditMenuModal from './EditMenu';
import { Radio } from 'antd';
import { Header } from 'antd/es/layout/layout';

const moveItemOfMenus = (items: any, draggableId: any, index: any) => {
  const newItems = [...items];

  for (let i = 0; i < newItems.length; i++) {
    const item = newItems[i];

    if (item._id === draggableId) {
      newItems.splice(i, 1);
      newItems.splice(index, 0, item);
      return newItems;
    }

    if (item.children) {
      for (let j = 0; j < item.children.length; j++) {
        const child = item.children[j];

        if (child._id === draggableId) {
          item.children.splice(j, 1);
          item.children.splice(index, 0, child);
          return newItems;
        }

        if (child.children) {
          for (let k = 0; k < child.children.length; k++) {
            const grandchild = child.children[k];

            if (grandchild._id === draggableId) {
              child.children.splice(k, 1);
              child.children.splice(index, 0, grandchild);
              return newItems;
            }
          }
        }
      }
    }
  }

  return newItems;
};

const DynamicMenu = () => {
  const [menuType, setMenuType] = useState('HEADER');
  const { data: menuItems, isLoading: isLoadingMenus } = useGetMenus(menuType);
  const { handleSubmit, isLoading: isLoadingUpdate } = useUpdateMenu();
  const { handleSubmit: handleDelete, isLoading: isLoadingDelete } =
    useDeleteMenu();

  const menuColors = ['#1e81b0', '#e28743', '#b97455'];
  const menuAddColors = ['#363636', '#696969', 'Gray51'];

  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});
  const [isOpen, setIsOpen] = useState<any>(false);
  const [prop, setProp] = useState<any>({});
  const [idMenu, setIdMenu] = useState();
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false);
  const [parentId, setParentId] = useState<any>();
  const [position, setPosition] = useState<any>();
  const [visibleEditModal, setVisibleEditModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>();
  const [menus, setMenus] = useState<any>();

  useEffect(() => {
    setMenus(menuItems);
  }, [menuItems]);

  const handleDragDrop = async (results: any) => {
    const { source, destination, type, draggableId } = results;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const updatedMenus = await moveItemOfMenus(
      menus,
      draggableId,
      destination.index
    );
    setMenus(updatedMenus);
    handleSubmit({
      id: draggableId,
      data: {
        position: results?.destination?.index,
      },
    });
  };

  const toggleMenu = (menuId: string) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menuId]: !prevOpenMenus[menuId],
    }));
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleDeleteMenu = () => {
    handleDelete(idMenu);
    setIsOpen(false);
  };

  const onCloseCreateModal = () => {
    setVisibleCreateModal(false);
  };
  const onCloseEditModal = () => {
    setVisibleEditModal(false);
    setItemSelected(undefined);
  };

  const handleMenuTypeChange = (e: any) => {
    setMenuType(e.target.value);
  };

  const renderMenuItem = (item: any, index: number, color: number) => {
    return (
      <Draggable draggableId={item._id} key={item._id} index={index}>
        {(provided, snapshot) => (
          <div
            className='pl-6 pt-2'
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className='flex relative'>
              <div
                className='p-4 w-full my-2 text-white rounded-md flex justify-between'
                style={{ backgroundColor: menuColors[color] }}
              >
                <span className='flex items-center justify-center'>
                  <MacCommandOutlined className='text-xl mr-6' />
                  <span>{item?.name}</span>
                </span>
                {item?.url && <span className='underline'>{item?.url}</span>}
                {item?.children?.length > 0 ? (
                  <button
                    onClick={() => toggleMenu(item._id)}
                    className='border-none hover:cursor-pointer'
                    style={{ backgroundColor: menuColors[color] }}
                  >
                    {openMenus[item._id] ? <UpOutlined /> : <DownOutlined />}
                  </button>
                ) : color !== 2 ? (
                  <button
                    onClick={() => {
                      setParentId(item._id);
                      setPosition(0);
                      setVisibleCreateModal(true);
                    }}
                    className='border-none hover:cursor-pointer'
                    style={{ backgroundColor: menuColors[color] }}
                  >
                    <PlusCircleOutlined className='text-xl' />
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <EditOutlined
                className='text-black p-2   text-xl hover:text-orange-600'
                onClick={() => {
                  setItemSelected(item);
                  setVisibleEditModal(true);
                }}
              />
              <DeleteOutlined
                className='text-black px-2 text-xl hover:text-orange-600'
                onClick={async () => {
                  setIdMenu(item._id);

                  setIsOpen(true);
                  setProp({
                    title: 'Xác nhận',
                    content: 'Bạn chắc chắn muốn xóa bản ghi này ?',
                  });
                }}
              />
            </div>

            <div>
              {item.children &&
                item.children.length > 0 &&
                openMenus[item._id] &&
                renderMenuItems(item.children, color + 1)}
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const renderMenuItems = (items: any[], color: number) => {
    return (
      <Droppable droppableId={`droppable-${uuid()}`} type={`group-${uuid()}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items?.map((item, index) => renderMenuItem(item, index, color))}
            <div className='ml-6 mt-6  rounded-md  bg-[#a7a6a6] my-2'>
              <button
                style={{ backgroundColor: menuAddColors[color] }}
                className='w-full p-2 text-xl text-white font-bold hover:cursor-pointer  rounded-md border-none shadow-md bg-[#b8b6b6]'
                onClick={() => {
                  setPosition(items?.length || 0);
                  setParentId(items[0]?.parentId);
                  setVisibleCreateModal(true);
                }}
              >
                <PlusOutlined />
              </button>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <>
      {isLoadingMenus || isLoadingDelete ? (
        <LoadingCenter />
      ) : (
        <>
          <div className='flex  p-4 mb-8 bg-white'>
            <h3>Chọn cấu hình:</h3>
            <Radio.Group
              value={menuType}
              onChange={handleMenuTypeChange}
              className='  ml-14'
            >
              <Radio value={'HEADER'} className='font-bold'>
                Header
              </Radio>
              <Radio value={'FOOTER'} className='font-bold'>
                Footer
              </Radio>
            </Radio.Group>
          </div>

          <DragDropContext onDragEnd={handleDragDrop}>
            {renderMenuItems(menus, 0)}
          </DragDropContext>
        </>
      )}
      <ModalConfirm
        isOpen={isOpen}
        onOk={handleDeleteMenu}
        onCancel={handleClose}
        prop={prop}
      />
      <CreateMenuModal
        visible={visibleCreateModal}
        onCancel={onCloseCreateModal}
        parent={parentId}
        type={menuType}
        position={position}
      />

      <EditMenuModal
        visible={visibleEditModal}
        onCancel={onCloseEditModal}
        itemSelected={itemSelected}
      />
    </>
  );
};

export default DynamicMenu;
